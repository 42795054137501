import React, { FC } from "react";

const GradientHeroTitle: FC = ({ children }) => (
  <span
    style={{
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      WebkitMaskImage: "linear-gradient(90deg, #38B9E2 0%, #C7DB4E 100%)",
      // Solve Webkit issue with Grandient in multiple lines
      // https://stackoverflow.com/questions/32084460/safari-doesnt-render-css-gradient-text
      WebkitBoxDecorationBreak: "clone",
      backgroundImage: "linear-gradient(90deg, #38B9E2 0%, #C7DB4E 100%)",
    }}
  >
    {children}
  </span>
);

export default GradientHeroTitle;
