import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Logo from "../../images/AccuRankerLogo.svg";
import { Popover } from "@headlessui/react";
import Transition from "../../ui-components/Transition";
import { MenuIcon } from "@heroicons/react/outline";
import assert from "assert";
import classNames from "classnames";
import SignIn from "../../icons/SignIn";
import {
  DropdownNav,
  NavSecondaryTextPoint,
  Panel,
} from "../../ui-components/NavigationComponents";
import { StrapiHeaderNavigationComponent } from "./headerType";
import { DeepNonNullable } from "ts-essentials";
import { HeaderNavigationQuery } from "../../../graphqlTypes";

/**
 *
 *    Helpers
 *
 */

const HeaderTitle = ({ children }: { open?: boolean; children: ReactNode }) => (
  <span
    className={classNames(
      "text-gray-100 font-medium group-hover:text-gray-500"
    )}
  >
    {children}
  </span>
);

const createNavigation = (
  item:
    | DeepNonNullable<StrapiHeaderNavigationComponent["Navigation"]>[number]
    | DeepNonNullable<
        DeepNonNullable<HeaderNavigationQuery>["allStrapiDropdown"]
      >["nodes"][number],
  allDropDowns: DeepNonNullable<HeaderNavigationQuery>["allStrapiDropdown"],
  // "dropdown" for Desktop navigation items, and "panel" for the mobile navigation item
  kind: "dropdown" | "panel" = "dropdown"
) => {
  /**
   * Steps of creating the header menu
   * 1. If the item includes a url, then it
   *    is just a clickable option that redirects:
   *    a. If the url is a relative url, then it
   *       is a Gatbsy link
   *    b. If the url is an absolute url, then we
   *       redirect to a new page
   *
   * 2. If the URL field is empty, then it is
   *    a Dropdown element. We have two dropdowns
   *    one with options and one with latest posts
   */
  if ("strapi_component" in item) {
    if (item.strapi_component === "header-menu.simple-link" && item.url) {
      if (item.url.startsWith("/")) {
        return (
          <Link
            to={item.url}
            className="text-base font-medium text-gray-500 hover:opacity-60"
          >
            <HeaderTitle>{item.title}</HeaderTitle>
          </Link>
        );
      } else {
        return (
          <a
            href={item.url}
            className="text-base font-medium text-gray-500 hover:opacity-60"
          >
            <HeaderTitle>{item.title}</HeaderTitle>
          </a>
        );
      }
    }
  }

  /**
   * Filter the dropdown items by the "Label"
   */
  const dropdownItem = allDropDowns.nodes.find(
    node => node.label === item.label
  );

  assert(dropdownItem);

  const leftMenu = dropdownItem.leftColumn.map(
    ({ title, subtitle, url, icon }) => {
      return {
        name: title,
        description: subtitle,
        href: url,
        icon: icon?.localFile.publicURL,
      };
    }
  );

  let rightMenu;
  let blogPosts: NavSecondaryTextPoint[] | undefined = undefined;
  /** In this case we should display articles on the right side */
  if (dropdownItem.articles) {
    blogPosts = dropdownItem.blog_posts.map(post => ({
      text: post.title,
      href: post.slug,
    }));
  } else if (dropdownItem.rightColumn && dropdownItem.rightColumn.length > 0) {
    rightMenu = dropdownItem.rightColumn.map(({ title, url, icon }) => {
      return {
        name: title,
        href: url,
        icon: icon?.localFile.publicURL,
      };
    });
  }

  if (kind === "dropdown") {
    return (
      <DropdownNav
        title={item.label || "Missing title"}
        points={leftMenu}
        secondaryPoints={rightMenu}
        secondaryText={
          blogPosts
            ? { title: "Recent Blogposts", points: blogPosts }
            : undefined
        }
      />
    );
  } else {
    return (
      <Panel
        points={leftMenu}
        secondaryPoints={rightMenu}
        secondaryText={
          blogPosts
            ? { title: "Recent Blogposts", points: blogPosts }
            : undefined
        }
      />
    );
  }
};

const Header: React.FC = () => {
  const data = useStaticQuery<DeepNonNullable<HeaderNavigationQuery>>(graphql`
    query HeaderNavigation {
      strapiHeaderNavigation {
        Navigation
      }
      allStrapiDropdown {
        nodes {
          articles
          id
          label
          blog_posts {
            title
            slug
          }
          leftColumn {
            id
            title
            subtitle
            url
            icon {
              localFile {
                publicURL
              }
            }
          }
          rightColumn {
            id
            title
            url
            icon {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  /** We lose type-safety with Dynamic Components */
  const menuItems: DeepNonNullable<
    StrapiHeaderNavigationComponent["Navigation"]
  > = data.strapiHeaderNavigation.Navigation;

  /**
   * Strapi's nested components
   * fail to give besides type-safety nested
   * components with relationships (>2 levels)
   * and seems in v4 will be fixed
   * Source: https://github.com/strapi/strapi/issues/7454
   * Thus fetching all the Dropwdown items
   * and filtering them by the "Label" (so Label should be unique)
   * to display then in the nav bar
   *
   * WARNING:
   * https://strapi.servers.ac/admin/plugins/content-manager/singleType/application::header-navigation.header-navigation
   *
   * Always have the same Label name for the
   * Dynamic Zone entry and the Dropdown object label property
   */
  const menuDropdowns = data.allStrapiDropdown;

  const items = menuItems.map(item => createNavigation(item, menuDropdowns));

  const mobileNavigationItem = data.allStrapiDropdown.nodes.find(
    dropdown => dropdown.label === "Mobile Navigation"
  );

  let mobileMenu: JSX.Element | null;
  if (mobileNavigationItem) {
    mobileMenu = createNavigation(mobileNavigationItem, menuDropdowns, "panel");
  }

  return (
    <div id="header" className="z-30 top-0 sticky bg-accuRankerPurple-900">
      {/* -------- Mobile Navigation -------- */}
      <div className="md:hidden px-4">
        <Popover className="relative">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto mt-4 mb-4">
                <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
                  <div className="flex justify-start lg:w-0 lg:flex-1">
                    <Link to="/">
                      <span className="sr-only">AccuRanker Logo</span>
                      <img
                        className="h-8 m-auto w-auto sm:h-10"
                        src={Logo}
                        alt="AccuRanker Logo"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <Transition open={open}>
                <Popover.Panel className="absolute top-16 z-20 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                  {mobileMenu}
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
      {/* -------- Desktop Navigation -------- */}
      <div className="hidden md:block px-4 xl:px-0">
        <Popover className="relative">
          <>
            <div className="max-w-7xl mx-auto pt-0 mb-4 actual">
              <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                  <Link to="/">
                    <span className="sr-only">AccuRanker Logo</span>
                    <img
                      className="h-8 m-auto w-auto sm:h-10"
                      src={Logo}
                      alt="AccuRanker Logo"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </>
        </Popover>
      </div>
    </div>
  );
};

export default Header;
