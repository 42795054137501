/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import fontUrl1 from "../fonts/gg-regular.woff2";
import fontUrl2 from "../fonts/gg-medium.woff2";
import { ReactElement } from "react";
import { DeepNonNullable } from "ts-essentials";
import { SeoDataQuery } from "../../graphqlTypes";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

function Seo({
  title,
  description = "",
  lang = "en",
  meta = [],
  featuredImageURL = "https://www.accuranker.com/wp-content/uploads/2019/04/accurankerhomepage.jpg",
  children = null,
}: {
  title: string;
  description: string;
  lang: string;
  meta: any;
  featuredImageURL?: string;
  children?: ReactElement | null;
}) {
  const { site } = useStaticQuery<DeepNonNullable<SeoDataQuery>>(
    graphql`
      query SEOData {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const { siteUrl } = site.siteMetadata;

  return (
    <>
      <GatsbySeo
        // featuredImageURL
        language={"en"}
        title={title || defaultTitle}
        titleTemplate={"%s"}
        description={metaDescription}
        noindex={true}
      />
      <Helmet>
        <link
          rel="preload"
          as="font"
          crossOrigin="anonymous"
          type="font/woff2"
          href={fontUrl1}
        />
        <link
          rel="preload"
          as="font"
          crossOrigin="anonymous"
          type="font/woff2"
          href={fontUrl2}
        />
        {children}
      </Helmet>
    </>
  );
}

export default Seo;
