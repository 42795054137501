import { Link } from "gatsby";
import React from "react";

const domainRegex = /http[s]*:\/\/[www.]*accuranker\.com[/]?/;

const MDXLink: React.FC<{ href: string }> = ({
  href,
  ...rest
}: {
  href: string;
}) => {
  const sameDomain = domainRegex.test(href);

  if (sameDomain) {
    href = href.replace(domainRegex, "/");
  }

  if (href.startsWith("/")) {
    // If the last part of the path contains a dot, don't add a ending slash.
    if (!href.endsWith("/") && !href.split("/").pop()!.includes(".")) {
      href = `${href}/`;
    }
    return <Link to={href} {...rest} />;
  }

  if (!href.startsWith("http")) {
    return <a href={href} {...rest} />;
  }

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer nofollow"
      {...rest}
    />
  );
};

export default MDXLink;
