import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import OfficeFooter from "../ui-components/OfficeFooter";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  StrapiFooterOffices,
  StrapiFooterSections,
  StrapiFooterTerms,
} from "../../graphqlTypes";

/**
 *
 * Footer Component
 *
 */

const Footer = () => {
  const footerData = useStaticQuery(graphql`
    query footer {
      strapiFooter {
        id
        offices {
          id
          flag {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: DOMINANT_COLOR)
              }
              extension
              publicURL
            }
            alternativeText
          }
          name
          phone
          email
          address
          vat
        }
        sections {
          id
          title
          links {
            id
            title
            url
          }
        }
        awards {
          images {
            id
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 140)
              }
              extension
              publicURL
            }
            alternativeText
          }
        }
        terms {
          title
          url
        }
        acceptedPaymentMethods {
          images {
            id
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 57)
              }
              extension
              publicURL
            }
            alternativeText
          }
        }
      }
    }
  `);

  const { offices, sections, awards, terms, acceptedPaymentMethods } =
    footerData.strapiFooter;

  return (
    <footer className="z-0" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      {/* Footer Navigation Section */}
      <div className="max-w-7xl mx-auto py-12 lg:py-16 xl:px-0 px-4">
        {/* Footer links */}
        {/*<div className="pb-12 xl:grid xl:grid-cols-4 xl:gap-8">*/}
        {/*  {sections.map((section: StrapiFooterSections, idx: number) => (*/}
        {/*    <div key={idx}>*/}
        {/*      <h3 className="text-base font-sans font-medium text-white tracking-wider">*/}
        {/*        {section.title}*/}
        {/*      </h3>*/}
        {/*      <ul className="mt-4 ml-0 space-y-4">*/}
        {/*        {section.links.map(item => (*/}
        {/*          <li key={item.id}>*/}
        {/*            <Link*/}
        {/*              to={item.url}*/}
        {/*              className="text-base text-gray-400 hover:text-white"*/}
        {/*            >*/}
        {/*              {item.title}*/}
        {/*            </Link>*/}
        {/*          </li>*/}
        {/*        ))}*/}
        {/*      </ul>*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {/*</div>*/}

        {/* Offices Section */}
        {/*<div className="pb-8 xl:grid xl:grid-cols-4 xl:gap-8">*/}
        {/*  {offices.map((office: StrapiFooterOffices) => (*/}
        {/*    <OfficeFooter office={office} />*/}
        {/*  ))}*/}
        {/*</div>*/}

        {/* Awards Section */}
        <div className="pb-8 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 xl:gap-8 md:mt-14 mb-14">
          {awards.images.map((awardImage: any, ix: number) => {
            if (
              !awardImage.localFile.childImageSharp &&
              awardImage.localFile.extension === "svg"
            ) {
              return (
                <img
                  key={ix}
                  src={awardImage.localFile.publicURL}
                  alt={awardImage.alternativeText}
                  className="m-auto my-6 xl:my-0"
                />
              );
            } else {
              return (
                <GatsbyImage
                  key={ix}
                  image={awardImage.localFile.childImageSharp.gatsbyImageData}
                  alt={awardImage.alternativeText}
                  className="m-auto my-6 xl:my-0"
                  objectFit="contain"
                />
              );
            }
          })}
        </div>

        {/* Accepted Payment Methods Section */}
        <div className="flex justify-center space-x-6 mb-6">
          {acceptedPaymentMethods.images.map((accepted: any, ix: number) => {
            if (
              !accepted.localFile.childImageSharp &&
              accepted.localFile.extension === "svg"
            ) {
              return (
                <img
                  key={ix}
                  src={accepted.localFile.publicURL}
                  alt={accepted.alternativeText}
                  width={57}
                  height={38}
                />
              );
            } else {
              return (
                <GatsbyImage
                  key={ix}
                  image={accepted.localFile.childImageSharp.gatsbyImageData}
                  alt={accepted.alternativeText}
                />
              );
            }
          })}
        </div>

        {/* Terms Section */}
        {/*<div className="flex flex-row text-gray-400 gap-x-12 text-center w-full justify-center mb-6">*/}
        {/*  {terms.map((term: StrapiFooterTerms, idx: number) => (*/}
        {/*    <Link*/}
        {/*      key={idx}*/}
        {/*      to={term.url}*/}
        {/*      className="text-base text-gray-400 hover:text-white"*/}
        {/*    >*/}
        {/*      {term.title}*/}
        {/*    </Link>*/}
        {/*  ))}*/}
        {/*</div>*/}

        {/* Copyright section */}
        <div className="flex flex-row text-gray-200 text-center w-full justify-center mt-14">
          AccuRanker 2022. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
