import React, { ReactNode } from "react";
import { Popover } from "@headlessui/react";
import Transition from "../ui-components/Transition";
import { ChevronDownIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { XIcon } from "@heroicons/react/outline";
import { ChevronRightIcon } from "@heroicons/react/solid";
import SVG from "react-inlinesvg";
import { Link } from "gatsby";

const HeaderTitle = ({ children }: { open?: boolean; children: ReactNode }) => (
  <span
    className={classNames(
      "text-gray-100 font-medium group-hover:text-gray-500"
    )}
  >
    {children}
  </span>
);

const DropdownButton = ({ open }: { open: boolean }) => (
  <ChevronDownIcon
    className={classNames(
      open
        ? "text-accuRankerOrange-500 opacity-60"
        : "text-accuRankerOrange-500",
      "ml-2 h-5 w-5 group-hover:opacity-60"
    )}
    aria-hidden="true"
  />
);

type NavMainPoint = {
  name: string;
  description: string;
  href: string;
  icon: string;
};

type NavSecondaryIconPoint = {
  name: string;
  href: string;
  icon: string;
};

export type NavSecondaryTextPoint = {
  text: string;
  href: string;
};

type DropdownNavProps = {
  title: string;
  points: NavMainPoint[];
  mobile?: boolean;
  secondaryPoints?: NavSecondaryIconPoint[];
  secondaryText?: {
    title: string;
    points: NavSecondaryTextPoint[];
  };
};

export const Panel = ({
  mobile = false,
  points,
  secondaryText,
  secondaryPoints,
}: Pick<
  DropdownNavProps,
  "mobile" | "points" | "secondaryText" | "secondaryPoints"
>) => (
  <div className="rounded-lg m-auto shadow-lg w-max max-w-md lg:max-w-2xl flex flex-col lg:flex-row ring-1 ring-black ring-opacity-5 overflow-hidden">
    {/* Main White Area */}
    <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 sm:max-w-none">
      {mobile && (
        <div className="flex justify-end">
          <div className="-mr-2">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Close menu</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
        </div>
      )}
      <div>
        {points?.map(item => (
          <a
            key={item.name}
            href={item.href}
            className="-m-3 px-3 py-2 my-3 flex items-start rounded-lg hover:bg-gray-200 max-w-sm"
          >
            <img
              className="flex-shrink-0 h-6 w-6 pt-1 text-accuRankerOrange-300"
              aria-hidden="true"
              src={item.icon}
            />
            <div className="ml-4">
              <p className="text-base mb-0 font-medium text-gray-900">
                {item.name}
              </p>
              <p className="mt-1 mb-0 text-sm text-gray-500">
                {item.description}
              </p>
            </div>
          </a>
        ))}
      </div>
    </div>
    {/* Secondary Actions */}
    {secondaryPoints && secondaryPoints.length > 0 && (
      <div className="py-12 px-6 bg-accuRankerOrange-300 flex flex-col gap-12 w-full md:w-auto max-w-none md:max-w-none">
        {secondaryPoints?.map(item => (
          <div key={item.name} className="flow-root">
            <a
              href={item.href}
              className="-m-3 group header-image-secondary p-3 whitespace-nowrap flex items-center rounded-md text-base font-medium text-white hover:bg-gray-100"
            >
              <SVG
                src={item.icon}
                className="navigation-icon text-white group-hover:text-accuRankerOrange-300"
              />
              <span className="ml-3 text-accuRankerPurple-900 group-hover:text-accuRankerOrange-300">
                {item.name}
              </span>
            </a>
          </div>
        ))}
      </div>
    )}
    {secondaryText && (
      <div className="px-5 py-5 bg-accuRankerOrange-300 sm:p-8 max-w-fit md:max-w-none">
        <span className="text-accuRankerPurple-900  font-medium">
          {secondaryText.title}
        </span>
        <ul className="m-0 p-0">
          {secondaryText.points.map((item, ix) => (
            <div
              className="hover:opacity-70 flex flex-row gap-y-2 mt-2"
              key={ix}
            >
              <div className="flex-grow-0 w-6 h-6">
                <ChevronRightIcon className="text-white w-6 h-6 flex-grow-0" />
              </div>
              <Link
                to={`/blog/${item.href}`}
                className="text-white font-medium"
              >
                {item.text}
              </Link>
            </div>
          ))}
        </ul>
      </div>
    )}
  </div>
);

export const DropdownNav = ({ title, ...props }: DropdownNavProps) => (
  <Popover className="relative">
    {({ open }) => (
      <>
        <Popover.Button
          className={classNames(
            open ? "text-gray-900" : "text-gray-500",
            "group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 outline-none"
          )}
        >
          <HeaderTitle open={open}>{title}</HeaderTitle>
          <DropdownButton open={open} />
        </Popover.Button>

        <Transition open={open}>
          <Popover.Panel
            static
            className="absolute z-10 mt-3 px-2 w-screen max-w-md lg:max-w-2xl sm:px-0 left-1/2 transform -translate-x-1/2"
          >
            <Panel {...props} />
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
);
