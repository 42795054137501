import React, { Fragment, ReactNode } from "react";
import { Transition } from "@headlessui/react";

const TransitionWrapper = ({
  children,
  open,
}: {
  children: ReactNode;
  open: boolean;
}) => (
  <Transition
    show={open}
    as={Fragment}
    enter="duration-200 ease-out"
    enterFrom="opacity-0 scale-95"
    enterTo="opacity-100 scale-100"
    leave="duration-100 ease-in"
    leaveFrom="opacity-100 scale-100"
    leaveTo="opacity-0 scale-95"
  >
    {children}
  </Transition>
);

export default TransitionWrapper;
