/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { ReactNode } from "react";
import { MDXProvider } from "@mdx-js/react";
import GradientHeroTitle from "../ui-components/GradientHeroTitle";
import OrangeText from "../ui-components/OrangeText";
import Header from "./Header/Header";

import "./layout.css";
import NumberFormat from "react-number-format";
import MDXLink from "@components/MDXLink";

const MDXMapping = {
  GradientHeroTitle,
  OrangeText,
  FormatNumber: ({ ...props }) => {
    const { children } = props;
    return (
      <NumberFormat
        value={children}
        displayType={"text"}
        thousandSeparator={true}
      />
    );
  },
  a: MDXLink,
};

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <div>
      <Header />
      <div
        style={{
          float: "none",
          maxWidth: "1280px",
        }}
        className="mx-auto"
      >
        <MDXProvider components={MDXMapping}>
          <main>{children}</main>
        </MDXProvider>
      </div>
    </div>
  );
};

export default Layout;
